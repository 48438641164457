<template>
  <section class="flex-1 w-full max-w-5xl justify-start mx-auto flex flex-col bg-white">
    <div class="md:mx-auto w-full">
      <div class="md:my-0 rounded-lg w-full h-full relative">
        <div class="w-full p-4">

          <div class="w-full rounded border border-red-500 bg-red-200 p-2 text-blue-900 mb-2 text-xs">
            <div class="flex mb-3 text-sm md:text-base">
              <span class="font-semibold text-blue-700 bg-white px-2 border-b border-red-500">
                ALIDAUNIA INFORMS
              </span>
            </div>
            <p class="text-justify mb-3 leading-tight">
              Passengers are informed that Alidaunia has implemented appropriate safety actions for assuring a comfortable and safe travel experience both on the ground and on board.
            </p>
            <p class="text-justify mb-3 leading-tight">
              All of our aircrafts are cleaned and disinfected with licensed products that are approved for aviation industry.
            </p>
            <p class="text-justify leading-tight">
              Alidaunia also recommends consulting the Health Safety Promotion form available at the <a href="/pdf/Health_Safety_Promotion_form.pdf" target="_blank" class="underline font-semibold">following link</a>.
            </p>
          </div>

          <div class="md:pt-2 text-blue-600 flex flex-wrap md:flex-no-wrap flex-col">
            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-col md:flex-row text-gray-600 md:flex-wrap gap-1 text-xs leading-none">
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_OUR_PROMISE" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.OUR_PROMISE }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_GENERAL_CONDITIONS_OF_CARRIAGE" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.GENERAL_CONDITIONS_OF_CARRIAGE }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_PASSENGER_INFORMATION" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.PASSENGER_INFORMATION }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS }}</span>
                </a>
              </div>
              <div class="w-full flex flex-col md:flex-row text-gray-600 md:flex-wrap gap-1 text-xs leading-none">
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_SPECIAL_ASSISTANCE_REGULATIONS" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.SPECIAL_ASSISTANCE_REGULATIONS }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_PARKING_REGULATIONS" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.PARKING_REGULATIONS }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_INTEGRATED_MANAGEMENT_SYSTEM_POLICY" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.INTEGRATED_MANAGEMENT_SYSTEM_POLICY }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_INFO_PRIVACY" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.INFO_PRIVACY }}</span>
                </a>
              </div>

              <div class="mt-6 w-full flex flex-col border border-gray-300 rounded p-1">
                <span class="text-gray-400 text-xs mb-1">Pubblicità</span>
                <div class="flex flex-col lg:flex-row lg:justify-center lg:gap-x-5 items-center">
                  <div class="flex justify-center items-center gap-x-8 lg:gap-x-5">
                    <a href="https://www.facebook.com/flygargano" target="_blank">
                      <img src="/img/loghi/logo_flygargano.png">
                    </a>
                    <a href="http://www.welcomegargano.it" target="_blank">
                      <img src="/img/loghi/logo_welcome_gargano.png">
                    </a>
                  </div>
                  <div class="mt-4 lg:mt-0 flex justify-center items-center gap-x-8 lg:gap-x-5">
                    <a href="http://www.marlintremiti.com/" target="_blank">
                      <img src="/img/loghi/marlintremiti.png">
                    </a>
                    <a href="http://www.infotremiti.com" target="_blank">
                      <img src="/img/loghi/logo_infotremiti.png">
                    </a>
                  </div>
                  <div class="mt-4 lg:mt-0 flex items-center gap-x-8 lg:gap-x-5">
                    <div class="flex justify-center items-center">
                      <a href="https://padpilot.com" target="_blank" style="background-color: #0b4584; padding: 5px; border-radius: 3px">
                        <img src="/img/loghi/logo_padpilot.svg" style="width: 138px;">
                      </a>
                    </div>
                    <div class="flex justify-center items-center">
                      <a href="http://alilife.it" target="_blank">
                        <img src="/img/loghi/logo_alilife.png" style="width: 141px;">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex mt-10">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-2xl font-semibold text-blue-900 text-center px-3">ALIDAUNIA</span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="mt-3 w-full flex flex-col">

            <div class="flex flex-col sm:flex-row border-b pb-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }" class="text-left">
                  <img class="w-full" src="/img/info-block-00.jpg" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Scheduled and charter flights
                </router-link>
                <p class="text-justify">
                  Daily helicopter line service: Foggia - Tremiti Islands.<br>
                  Since 1985, with ministerial assent and in collaboration with the Assessorato ai Trasporti of the Regione Puglia, guarantees daily connection between Foggia and the Tremiti Islands, a service of remarkable social utility.<br>
                  Mondays and Fridays stopover in Vieste, during summer even Saturdays and Sundays.<br>
                  Flexible, fast Helicopter Charter Vip Service for your business needs; Air Ambulance for the health of your loved ones.
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-01.jpg" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Helicopter Emergency Medical Services
                </router-link>
                <p class="text-justify">
                  Alidaunia is entitled to the civil protection services, Helicopter Emergency Medical Service (HEMS) and air ambulance on behalf of Public Administration in several Italian regions.
                  Part of Alidaunia’s fleet is dedicated to air ambulance services. Our helicopters are suitably equipped and staffed by trained personnel. The benefits of helicopters employment for medical emergencies are well-known all over the world: we deliver medical help very swiftly, provide fast transport to the nearest hospital and save hundreds of lives every year.
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <div class="flex flex-row sm:flex-col">
                  <img class="hidden sm:block w-full" src="/img/info-block-02.jpg" />
                  <img class="mt-2 w-full" src="/img/hangar.jpg" />
                  <img class="hidden sm:block mt-2 w-full" src="/img/hangar-02.png" />
                </div>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Aircraft Maintenance
                </router-link>
                <div class="w-full flex flex-col">
                  <div class="flex">
                    <div class="w-1/4">
                    </div>
                    <div class="w-3/4">
                    </div>
                  </div>
                </div>
                <p class="text-justify">
                  <img class="h-16" src="/img/loghi-home/ExcellentLeonardo.jpg">
                  Since 2002 Agusta Service Center for the maintenance of AgustaWestland helicopters.
                  Alidaunia has been the first Service Center non-Leonardo to obtain the "certificate of Excellent".
                  Among 89 Service Centers worldwide, only 3 are rated "Excellent".
                </p>
                <div class="mt-3 w-full flex flex-col">
                  <div class="flex items-center">
                    <div class="w-1/4">
                      <img src="/img/loghi/robinson.jpg">
                    </div>
                    <div class="ml-3 w-3/4">
                      Since 2011 Authorized Service Center for the maintenance of Robinson helicopters.
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="w-1/4">
                      <img src="/img/loghi/tronair.jpg">
                    </div>
                    <div class="ml-3 w-3/4">
                      Since 2012 Authorized Service Center Tronair.
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="w-1/4">
                      <img src="/img/loghi/laversab.jpg">
                    </div>
                    <div class="ml-3 w-3/4">
                      Since 2014 Reseller and Authorized Service Center Laversab.
                    </div>
                  </div>
                  <p class="text-justify">
                    <img class="h-16 mb-2" src="/img/loghi-home/PremiumPratt.jpg">
                    On November 2017 becomes Premium Service Provider Pratt&Whittney as Mobile Repair Team Service for line maintenance.
                    Since June 2019 is also PRI - Post Rental Inspection.
                  </p>
                </div>
                <div class="mt-6 w-1/4"><img src="/img/loghi-home/logo-difesa.png"></div> <div class="mt-2 w-full text-justify">
                    Since 2020-01-27 Certified "Ministero della Difesa - Direzione degli Armamenti Aeronautici e per l'Aeronavigabilità" as AER(EP).P-145 (EMAR 145) Maintenance Organisation Approved
                 </div>
                 <a href="/pdf/Certificato-EMAR-145.pdf" target="_blank">
                    <fa class="ml-3 r-file-pdf text-base text-red-600" />
                 </a>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link class="flex flex-row sm:flex-col" :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-03.jpg" />
                  <img class="hidden sm:block mt-2 w-full" src="/img/aula.png" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Approved Training Organization
                </router-link>
                <p class="text-justify">
                  In addition to pilot license and training courses, Alidaunia Training offers courses for:
                  <ul class="list-unstyled" style="margin-top:5px;margin-left:20px;list-style-image:url('/img/turbine.png');">
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        knowledge of aviation standards JAR FCL/OPS
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        helicopter approach
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        firefighting training for airports/heliports/helipads
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        refuelling operators
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        training for medical crew
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        rescue hoist training
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        Helicopter Underwater Escape Training (HUET) (in collaboration with S.I.E.M.)
                      </span>
                    </li>
                  </ul>
                  Alidaunia is also:<br><br>
                  <div class="flex justify-between items-center">
                    <img src="/img/loghi/tea.jpg" style="width:100px">
                    <router-link :to="{ name: 'company-tea-centre' }" class="bg-blue-700 hover:bg-blue-500 text-xs text-white py-1 px-2 rounded shadow-xl">
                      Centre for TEA Exams
                    </router-link>
                  </div>
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-04.jpg" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Heliports Design Construction & Management
                </router-link>
                <p class="text-justify">
                  Heliports designed and so far completed by Alidaunia guarantee continuous service during day and night.<br>
                  They are furnished with all necessary safety equipments such as antitheft devices, firefighting and check-in for passengers and baggage, as well as other services like handling of aircrafts and passengers and refuelling.<br>
                  All sites are remote controlled and managed from a 24/7 Operational Centre, located at our main base in Foggia, assuring continuous monitoring of the network, facilities and services to guarantee uninterrupted availability of the system.<br>
                  Alidaunia produces a totem providing all heliport's management functions.
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-05.png" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Design Organisation
                </router-link>
                <p class="text-justify">
                  With E.A.S.A. letter of May 13th 2005, ref. n° EASA D (2005) CDO/AP018/6198, Alidaunia has been found compliant to carry out design activity according to the alternative procedures to Design Organisation Approval.<br>
                  Alidaunia has developed minor and major modifications (S.T.C.) in the following areas:
                  <ul class="list-unstyled" style="margin-top:5px;margin-left:20px;list-style-image:url('/img/turbine.png');">
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        installation of special mission equipments
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        installation of communication and data link systems
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        installation of static pressure systems
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        variations to the Rotorcraft Flight Manuals procedures
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>

          </div>
          <div class="mt-10 mb-3 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-2xl font-semibold text-blue-900 text-center px-3">APULIAN HELIPORT NETWORK</span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex flex-col sm:flex-row justify-between">
            <div class="w-full sm:w-1/2 flex flex-col">
              <p class="text-justify">
                Apulian Heliport Network has been built and now is managed by Alidaunia within the framework of a specific contract awarded by Aeroporti di Puglia.
                Such network responds to the demand for a system of helicopter landing points, operating 24/7, strategically distributed on the regional territory and, above all, managed jointly in order to integrate with the airports. It still constitutes the only example in Italy of integrated management of a regional airport/ heliport system.
                Each heliport is furnished with any necessary safety and security equipment: antitheft devices, firefighting and check-in for passengers and baggage (where required), as well as other services like handling, refuelling (Jet A1 - where required).
              </p>
              <div class="flex justify-center">
                <img class="mt-2" src="/img/rete-puglia.jpg" />
              </div>
            </div>
            <div class="w-full mt-5 sm:mt-0 sm:w-1/2 sm:ml-8">
              <div class="pb-3 font-semibold text-lg text-blue-900">Press review helipads inaugurations (ita)</div>
              <div class="w-full flex flex-col">
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'policlinico-bari' } }">
                    <img src="/img/events/10.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'policlinico-bari' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Policlinico Bari</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell 'ambito del progetto Rete Eliportuale Regione Puglia, il Presidente Vendola inaugura il servizio di elisoccorso del Policlinico di Bari
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'policlinico-bari' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'supersano' } }">
                    <img src="/img/events/09.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'supersano' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Supersano</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell'ambito del progetto Rete Eliportuale Regione Puglia è stata inaugurata dall'assessore alle infrastrutture Guglielmo Minervini la nuova elisuperficie di Supersano
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'supersano' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'peschici' } }">
                    <img src="/img/events/07.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'peschici' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Peschici</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell'ambito del progetto Rete Eliportuale Regione Puglia è stata inaugurata dall'assessore alle infrastrutture Guglielmo Minervini la nuova elisuperficie di Peschici
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'peschici' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'celenza-valfortore' } }">
                    <img src="/img/events/06.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'celenza-valfortore' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Celenza Valfortore</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell'ambito del progetto Rete Eliportuale Regione Puglia è stata inaugurata dall'assessore alle infrastrutture Guglielmo Minervini la nuova elisuperficie di Celenza Valfortore
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'celenza-valfortore' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-3 flex justify-end">
                  <router-link class="bg-blue-700 hover:bg-blue-500 text-xs text-white py-1 px-2 rounded shadow-xl" :to="{ name: 'helipad-posts' }">
                    read more
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10 mb-5 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex justify-center sm:justify-start">
            <div class="w-1/2 flex justify-center md:justify-start md:w-1/3">
              <img class="w-40 md:w-48" src="/img/loghi-home/RegionePuglia.jpg" />
            </div>
            <div class="w-1/2 flex justify-center md:justify-start md:w-1/3">
              <img class="w-40 md:w-48" src="/img/loghi-home/AeroportiDiPuglia.jpg" />
            </div>
          </div>
          <div class="my-5 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex flex-col md:flex-row justify-center items-center">
            <div class="h-16">
              <img class="h-full" src="/img/loghi-home/ExcellentLeonardo.jpg" />
            </div>
            <div class="h-16 md:ml-10">
              <img class="h-full" src="/img/loghi-home/PremiumPratt.jpg" />
            </div>
          </div>
          <div class="my-5 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex flex-col lg:flex-row mb-3">
            <div class="w-full lg:w-3/4 flex flex-col lg:border-r-2">
              <div v-if="lang == 'it'" class="mb-2 text-xs text-gray-500">Centro servizi autorizzato:</div>
              <div v-if="lang == 'en'" class="mb-2 text-xs text-gray-500">Authorized service centre:</div>
              <div class="flex justify-center">
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Dart.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Skytrac.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Tronair.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Robinson.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Laversab.jpg" />
                </div>
              </div>
            </div>
            <div class="mt-5 lg:mt-0 lg:ml-8">
              <div v-if="lang == 'it'" class="mb-2 text-xs text-gray-500 mr-3">Certificazioni:</div>
              <div v-if="lang == 'en'" class="mb-2 text-xs text-gray-500 mr-3">Certifications:</div>
              <img src="/img/loghi-home/Certificazioni.jpg" style="width:300px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'home-page-en',
  computed: {
    lang() {
      return this.$parent.lang
    },
    locale() {
      return Locales[this.lang]
    },
    DOC_LINK_OUR_PROMISE() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_OUR_PROMISE`]
    },
    DOC_LINK_GENERAL_CONDITIONS_OF_CARRIAGE() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_GENERAL_CONDITIONS_OF_CARRIAGE`]
    },
    DOC_LINK_PASSENGER_INFORMATION() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_PASSENGER_INFORMATION`]
    },
    DOC_LINK_REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS`]
    },
    DOC_LINK_SPECIAL_ASSISTANCE_REGULATIONS() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_SPECIAL_ASSISTANCE_REGULATIONS`]
    },
    DOC_LINK_PARKING_REGULATIONS() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_PARKING_REGULATIONS`]
    },
    DOC_LINK_INTEGRATED_MANAGEMENT_SYSTEM_POLICY() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_INTEGRATED_MANAGEMENT_SYSTEM_POLICY`]
    },
    DOC_LINK_INFO_PRIVACY() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_INFO_PRIVACY`]
    }
  }
}
</script>
