<template>
  <section class="flex-1 w-full max-w-5xl justify-start mx-auto flex flex-col bg-white">
    <div class="md:mx-auto w-full">
      <div class="md:my-0 rounded-lg w-full h-full relative">
        <div class="w-full p-4">

          <div class="w-full rounded border border-red-500 bg-red-200 p-2 text-blue-900 mb-2 text-xs">
            <div class="flex mb-3 text-sm md:text-base">
              <span class="font-semibold text-blue-700 bg-white px-2 border-b border-red-500">
                ALIDAUNIA INFORMA
              </span>
            </div>
            <p class="text-justify mb-3 leading-tight">
              Si informano i passeggeri che Alidaunia ha adottato le opportune azioni di sicurezza per garantire un’esperienza di viaggio confortevole e sicura sia a terra che a bordo.
            </p>
            <p class="text-justify mb-3 leading-tight">
              Tutti i nostri aeromobili vengono puliti e sanificati con prodotti certificati ed approvati per uso aeronautico.
            </p>
            <p class="text-justify leading-tight">
              Alidaunia consiglia inoltre la consultazione del form di Health Safety Promotion disponibile a <a href="/pdf/Health_Safety_Promotion_form.pdf" target="_blank" class="underline font-semibold">questo link</a>.
            </p>
          </div>

          <div class="md:pt-2 text-blue-600 flex flex-wrap md:flex-no-wrap flex-col">
            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-col md:flex-row text-gray-600 md:flex-wrap gap-1 text-xs leading-none">
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_OUR_PROMISE" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.OUR_PROMISE }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_GENERAL_CONDITIONS_OF_CARRIAGE" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.GENERAL_CONDITIONS_OF_CARRIAGE }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_PASSENGER_INFORMATION" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.PASSENGER_INFORMATION }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS }}</span>
                </a>
              </div>
              <div class="w-full flex flex-col md:flex-row text-gray-600 md:flex-wrap gap-1 text-xs leading-none">
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_SPECIAL_ASSISTANCE_REGULATIONS" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.SPECIAL_ASSISTANCE_REGULATIONS }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_PARKING_REGULATIONS" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.PARKING_REGULATIONS }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_INTEGRATED_MANAGEMENT_SYSTEM_POLICY" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.INTEGRATED_MANAGEMENT_SYSTEM_POLICY }}</span>
                </a>
                <a class="flex-1 flex justify-start items-center space-x-1 border rounded p-1 text-white bg-blue-400 border-blue-500 hover:bg-blue-500" :href="DOC_LINK_INFO_PRIVACY" target="_blank">
                  <fa class="r-file-pdf text-base text-red-600" />
                  <span>{{ locale.INFO_PRIVACY }}</span>
                </a>
              </div>

              <div class="mt-6 w-full flex flex-col border border-gray-300 rounded p-1">
                <span class="text-gray-400 text-xs mb-1">Pubblicità</span>
                <div class="flex flex-col lg:flex-row lg:justify-center lg:gap-x-5 items-center">
                  <div class="flex justify-center items-center gap-x-8 lg:gap-x-5">
                    <a href="https://www.facebook.com/flygargano" target="_blank">
                      <img src="/img/loghi/logo_flygargano.png">
                    </a>
                    <a href="http://www.welcomegargano.it" target="_blank">
                      <img src="/img/loghi/logo_welcome_gargano.png">
                    </a>
                  </div>
                  <div class="mt-4 lg:mt-0 flex justify-center items-center gap-x-8 lg:gap-x-5">
                    <a href="http://www.marlintremiti.com/" target="_blank">
                      <img src="/img/loghi/marlintremiti.png">
                    </a>
                    <a href="http://www.infotremiti.com" target="_blank">
                      <img src="/img/loghi/logo_infotremiti.png">
                    </a>
                  </div>
                  <div class="mt-4 lg:mt-0 flex items-center gap-x-8 lg:gap-x-5">
                    <div class="flex justify-center items-center">
                      <a href="https://padpilot.com" target="_blank" style="background-color: #0b4584; padding: 5px; border-radius: 3px">
                        <img src="/img/loghi/logo_padpilot.svg" style="width: 138px;">
                      </a>
                    </div>
                    <div class="flex justify-center items-center">
                      <a href="http://alilife.it" target="_blank">
                        <img src="/img/loghi/logo_alilife.png" style="width: 141px;">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex mt-3">
            <a href="/company/finanzia-il-tuo-addestramento">
              <img src="/img/loghi/bnl-banner-xl.png" />
            </a>
          </div>

          <div class="w-full flex mt-10">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-2xl font-semibold text-blue-900 text-center px-3">ALIDAUNIA</span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="mt-3 w-full flex flex-col">

            <div class="flex flex-col sm:flex-row border-b pb-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }" class="text-left">
                  <img class="w-full" src="/img/info-block-00.jpg" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Voli di linea e voli charter
                </router-link>
                <p class="text-justify">
                  Servizio di linea giornaliero con elicottero: Foggia - Isole Tremiti.<br>
                  Dal 1985, con assenso ministeriale e in collaborazione con
                  l’Assessorato ai Trasporti della Regione Puglia, garantisce il
                  collegamento quotidiano tra Foggia e le Isole Tremiti,
                  fornendo in tal modo un servizio di rilevante utilità sociale.<br>
                  Ogni lunedì e venerdì si effettua scalo a Vieste, nel periodo estivo
                  anche sabato e domenica.<br>
                  Servizio Charter VIP flessibile e veloce per le tue esigenze di
                  business; eliambulanza per la salute dei tuoi cari.
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-01.jpg" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Servizi Elisoccorso e 118
                </router-link>
                <p class="text-justify">
                  Alidaunia è titolare di servizi di protezione civile, elisoccorso
                  (118) ed eliambulanza per conto di Pubbliche Amministrazioni
                  in varie regioni d'Italia.
                  Parte della flotta di Alidaunia è dedicata ai servizi di eliambulanza ed elisoccorso.
                  I nostri elicotteri sono opportunamente attrezzati e dotati di
                  personale addestrato. I vantaggi dell'impiego di elicotteri nelle
                  emergenze mediche sono ben noti In tutto il mondo: noi offriamo
                  una rapida assistenza medica, interventi sul luogo dell'incidente o del malore, un veloce trasporto all'ospedale
                  più vicino e salviamo centinaia di vite ogni anno.
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <div class="flex flex-row sm:flex-col">
                  <img class="hidden sm:block w-full" src="/img/info-block-02.jpg" />
                  <img class="mt-2 w-full" src="/img/hangar.jpg" />
                  <img class="hidden sm:block mt-2 w-full" src="/img/hangar-02.png" />
                </div>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Attività di Manutenzione
                </router-link>
                <div class="w-full flex flex-col">
                  <div class="flex">
                    <div class="w-1/4">
                    </div>
                    <div class="w-3/4">
                    </div>
                  </div>
                </div>
                <p class="text-justify">
                  <img class="h-16" src="/img/loghi-home/ExcellentLeonardo.jpg">
                  Dal 2002 Centro Servizi Agusta per la manutenzione degli elicotteri AgustaWestland.
                  Alidaunia è stato il primo Service Center non Leonardo ad ottenere il certificato di Ecellenza.
                  Su 89 Service Center nel mondo, solo 3 sono certificati Excellent.
                </p>
                <div class="mt-3 w-full flex flex-col">
                  <div class="flex items-center">
                    <div class="w-1/4">
                      <img src="/img/loghi/robinson.jpg">
                    </div>
                    <div class="ml-3 w-3/4">
                      Dal 2011 Centro Servizi Autorizzato per la manutenzione degli elicotteri Robinson.
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="w-1/4">
                      <img src="/img/loghi/tronair.jpg">
                    </div>
                    <div class="ml-3 w-3/4">
                      Dal 2012 Centro Servizi Autorizzato Tronair.
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="w-1/4">
                      <img src="/img/loghi/laversab.jpg">
                    </div>
                    <div class="ml-3 w-3/4">
                      Dal 2014 Rivenditore e Centro Servizi Autorizzato Laversab.
                    </div>
                  </div>
                  <p class="text-justify">
                    <img class="h-16 mb-2" src="/img/loghi-home/PremiumPratt.jpg">
                    Nel Novembre 2017 diventa Premium Service Provider Pratt&Whittney quale Mobile Repair Team Service per operazioni di line maintenance.
                    Da Giugno 2019 è anche PRI - Post Rental Inspection
                  </p>
                </div>
                <div class="mt-6 w-1/4"><img src="/img/loghi-home/logo-difesa.png"></div> <div class="mt-2 w-full text-justify">
                    Dal 27/01/2020 Certificata dal Ministero della Difesa - Direzione degli Armamenti Aeronautici e per l'Aeronavigabilità - come AER(EP).P-145 (EMAR 145) Maintenance Organisation Approved
                    <a href="/pdf/Certificato-EMAR-145.pdf" target="_blank">
                      <fa class="ml-3 r-file-pdf text-base text-red-600" />
                    </a>
                  </div>

              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link class="flex flex-row sm:flex-col" :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-03.jpg" />
                  <img class="hidden sm:block mt-2 w-full" src="/img/aula.png" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Scuola di addestramento al volo
                </router-link>
                <p class="text-justify">
                  Oltre a corsi per licenze ed abilitazioni ai piloti,
                  Alidaunia Training offre corsi per
                  <ul class="list-unstyled" style="margin-top:5px;margin-left:20px;list-style-image:url('/img/turbine.png');">
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        conoscenza degli standard aeronautici JAR FCL/OPS
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        avvicinamento all'aeromobile
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        operatori antincendio per aeroporti - eliporti - elisuperfici
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        carburantisti
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        addestramento per equipaggio sanitario
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        addestramento al verricello
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;">
                        corso di sopravvivenza in acqua (in collaborazione con
                        S.I.E.M. Società Italiana Emergenza Mare)
                      </span>
                    </li>
                  </ul>
                  Alidaunia è anche:<br><br>
                  <div class="flex justify-between items-center">
                    <img src="/img/loghi/tea.jpg" style="width:100px">
                    <router-link :to="{ name: 'company-tea-centre' }" class="bg-blue-700 hover:bg-blue-500 text-xs text-white py-1 px-2 rounded shadow-xl">
                      Centro per Esami TEA
                    </router-link>
                  </div>
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-04.jpg" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Progettazione, costruzione e gestione eliporti
                </router-link>
                <p class="text-justify">
                  Eliporti progettati e finora completati da Alidaunia garantiscono un servizio continuo, durante il giorno e la notte. Sono arredate con tutte le attrezzature di sicurezza necessarie, quali antifurto, antincendio e il check-In per passeggeri e bagagli, nonché altri servizi come la gestione degli aeromobili e dei passeggeri, rifornimento di carburante. Tutti i luoghi sono controllati a distanza e gestite da un Centro Operativo 24/7, che si trova presso la sede centrale di Foggia, che assicurano il monitoraggio continuo della rete, strutture e servizi per garantire la disponibilità continua del sistema.<br>
                  Alidaunia produce un totem che include tutte le funzioni per la gestione di un eliporto.
                </p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row border-b py-5">
              <div class="w-full flex justify-center sm:w-1/3">
                <router-link :to="{ name: '' }">
                  <img class="w-full" src="/img/info-block-05.png" />
                </router-link>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-5 w-full sm:w-2/3 flex flex-col">
                <router-link :to="{ name: '' }" class="text-center sm:text-left pb-3 font-semibold text-lg text-blue-900">
                  Organizzazione di progettazione
                </router-link>
                <p class="text-justify">
                  Con la lettera EASA del 13 maggio 2005, prot. n° EASA D (2005) CDO/AP018/6198, Alidaunia è stata riconosciuta idonea per svolgere attività di progettazione secondo le procedure alternative al Design Organisation Approval.<br>
                  Alidaunia ha sviluppato modifiche minori e maggiori (S.T.C.) nelle seguenti aree:
                  <ul class="list-unstyled" style="margin-top:5px;margin-left:20px;list-style-image:url('/img/turbine.png');">
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                          installazione equipaggiamenti per missioni speciali
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        installazione di sistemi di comunicazione voce e dati
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        installazione di sistemi di pressione statica
                      </span>
                    </li>
                    <li style="padding-bottom:10px;line-height:15px">
                      <span style="vertical-align:15%;text-align:justify">
                        variazione alle procedure del manuale di volo
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>

          </div>
          <div class="mt-10 mb-3 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-2xl font-semibold text-blue-900 text-center px-3">RETE ELIPORTUALE PUGLIESE</span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex flex-col sm:flex-row justify-between">
            <div class="w-full sm:w-1/2 flex flex-col">
              <p class="text-justify">
                La Rete eliportuale pugliese risponde alla esigenza di realizzare un sistema di punti di atterraggio per elicotteri, con operatività diurna e notturna, strategicamente distribuiti sul territorio regionale e, soprattutto, gestiti unitariamente e organicamente a similitudine di quanto avviene per il sistema aeroportuale che, tuttora, costituisce l'unico esempio in Italia di gestione unitaria di un intero sistema regionale.
              </p>
              <div class="flex justify-center">
                <img class="mt-2" src="/img/rete-puglia.jpg" />
              </div>
            </div>
            <div class="w-full mt-5 sm:mt-0 sm:w-1/2 sm:ml-8">
              <div class="pb-3 font-semibold text-lg text-blue-900">Rassegna stampa inaugurazioni elisuperfici</div>
              <div class="w-full flex flex-col">
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'policlinico-bari' } }">
                    <img src="/img/events/10.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'policlinico-bari' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Policlinico Bari</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell 'ambito del progetto Rete Eliportuale Regione Puglia, il Presidente Vendola inaugura il servizio di elisoccorso del Policlinico di Bari
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'policlinico-bari' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'supersano' } }">
                    <img src="/img/events/09.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'supersano' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Supersano</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell'ambito del progetto Rete Eliportuale Regione Puglia è stata inaugurata dall'assessore alle infrastrutture Guglielmo Minervini la nuova elisuperficie di Supersano
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'supersano' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'peschici' } }">
                    <img src="/img/events/07.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'peschici' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Peschici</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell'ambito del progetto Rete Eliportuale Regione Puglia è stata inaugurata dall'assessore alle infrastrutture Guglielmo Minervini la nuova elisuperficie di Peschici
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'peschici' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-4 flex items-start">
                  <router-link class="w-1/3" :to="{ name: 'helipad-post', params: { slug: 'celenza-valfortore' } }">
                    <img src="/img/events/06.jpg" class="mr-3">
                  </router-link>
                  <div class="ml-3 w-2/3 flex flex-col justify-start">
                    <router-link :to="{ name: 'helipad-post', params: { slug: 'celenza-valfortore' } }" class="font-semibold text-gray-800 hover:text-blue-500 mb-2 leading-none">Celenza Valfortore</router-link>
                    <p class="hidden md:block text-justify text-xs leading-tight sm:leading-normal">Nell'ambito del progetto Rete Eliportuale Regione Puglia è stata inaugurata dall'assessore alle infrastrutture Guglielmo Minervini la nuova elisuperficie di Celenza Valfortore
                      <router-link :to="{ name: 'helipad-post', params: { slug: 'celenza-valfortore' } }" class="text-blue-500 text-sm">...</router-link>
                    </p>
                  </div>
                </div>
                <div class="w-full mt-3 flex justify-end">
                  <router-link class="bg-blue-700 hover:bg-blue-500 text-xs text-white py-1 px-2 rounded shadow-xl" :to="{ name: 'helipad-posts' }">
                    vedi archivio
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10 mb-5 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex justify-center sm:justify-start">
            <div class="w-1/2 flex justify-center md:justify-start md:w-1/3">
              <img class="w-40 md:w-48" src="/img/loghi-home/RegionePuglia.jpg" />
            </div>
            <div class="w-1/2 flex justify-center md:justify-start md:w-1/3">
              <img class="w-40 md:w-48" src="/img/loghi-home/AeroportiDiPuglia.jpg" />
            </div>
          </div>
          <div class="my-5 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex flex-col md:flex-row justify-center items-center">
            <div class="h-16">
              <img class="h-full" src="/img/loghi-home/ExcellentLeonardo.jpg" />
            </div>
            <div class="h-16 md:ml-10">
              <img class="h-full" src="/img/loghi-home/PremiumPratt.jpg" />
            </div>
          </div>
          <div class="my-5 w-full flex">
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
          <div class="flex flex-col lg:flex-row mb-3">
            <div class="w-full lg:w-3/4 flex flex-col lg:border-r-2">
              <div v-if="lang == 'it'" class="mb-2 text-xs text-gray-500">Centro servizi autorizzato:</div>
              <div v-if="lang == 'en'" class="mb-2 text-xs text-gray-500">Authorized service centre:</div>
              <div class="flex justify-center">
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Dart.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Skytrac.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Tronair.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Robinson.jpg" />
                </div>
                <div class="flex justify-center">
                  <img class="h-12 mr-3" src="/img/loghi-home/Laversab.jpg" />
                </div>
              </div>
            </div>
            <div class="mt-5 lg:mt-0 lg:ml-8">
              <div v-if="lang == 'it'" class="mb-2 text-xs text-gray-500 mr-3">Certificazioni:</div>
              <div v-if="lang == 'en'" class="mb-2 text-xs text-gray-500 mr-3">Certifications:</div>
              <img src="/img/loghi-home/Certificazioni.jpg" style="width:300px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'home-page-it',
  computed: {
    lang() {
      return this.$parent.lang
    },
    locale() {
      return Locales[this.lang]
    },
    DOC_LINK_OUR_PROMISE() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_OUR_PROMISE`]
    },
    DOC_LINK_GENERAL_CONDITIONS_OF_CARRIAGE() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_GENERAL_CONDITIONS_OF_CARRIAGE`]
    },
    DOC_LINK_PASSENGER_INFORMATION() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_PASSENGER_INFORMATION`]
    },
    DOC_LINK_REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_REGULATIONS_FOR_THE_TRANSPORT_OF_BAGGAGE_PETS_GOODS`]
    },
    DOC_LINK_SPECIAL_ASSISTANCE_REGULATIONS() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_SPECIAL_ASSISTANCE_REGULATIONS`]
    },
    DOC_LINK_PARKING_REGULATIONS() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_PARKING_REGULATIONS`]
    },
    DOC_LINK_INTEGRATED_MANAGEMENT_SYSTEM_POLICY() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_INTEGRATED_MANAGEMENT_SYSTEM_POLICY`]
    },
    DOC_LINK_INFO_PRIVACY() {
      return process.env[`VUE_APP_DOC_LINK_${this.lang.toUpperCase()}_INFO_PRIVACY`]
    }
  }
}
</script>
